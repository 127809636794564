import * as Sentry from "@sentry/gatsby";

const activeEnv =
  process.env.GATSBY_ENV || process.env.NODE_ENV || "development";

Sentry.init({
  enabled: activeEnv !== 'development',
  debug: activeEnv === 'staging',
  dsn: process.env.GATSBY_SENTRY_DSN,
  release: "vic-landing@" + process.env.GATSBY_SENTRY_RELEASE,
  integrations: [
    new Sentry.Replay({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});
