/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import './src/styles/global.css';

export function onRouteUpdate (props) {
  if (window.vgo) {
    window.vgo('process');
  }
}